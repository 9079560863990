<template>
    <div class="customer-view">
        <div v-if="alertMessage" class="alert" :class="{ 'alert-error': isErrorAlert }" @click="dismissAlert">{{ alertMessage }}</div>

        <!-- Not Accepting Orders Warning -->
        <div v-if="!isAcceptingOrders" class="warning-banner">
            Robot Globar is currently unavailable. Please try again later.
        </div>

        <!-- Current Orders -->
        <div v-if="currentOrders.length > 0" class="order-section">
            <h3>Your Orders</h3>
            <div class="scrollable-container-wrapper">
                <div class="order-status-container scrollable-container" ref="currentOrdersContainer" @scroll="handleScroll('current')">
                    <CustomerOrderStatus 
                        :orders="currentOrders" 
                        @cancel="cancelOrder"
                    />
                </div>
                <div v-if="isCurrentOrdersScrollable" class="scroll-indicator" :style="{ opacity: currentOrdersScrollOpacity }"></div>
            </div>
        </div>

        <!-- Past Orders -->
        <div v-if="pastOrders.length > 0" class="order-section">
            <h3>Past Orders</h3>
            <div class="scrollable-container-wrapper">
                <div class="past-orders-container scrollable-container" ref="pastOrdersContainer" @scroll="handleScroll('past')">
                    <CustomerOrderStatus 
                        :orders="pastOrders" 
                        :isPastOrders="true"
                    />
                </div>
                <div v-if="isPastOrdersScrollable" class="scroll-indicator" :style="{ opacity: pastOrdersScrollOpacity }"></div>
            </div>
        </div>

        <!-- Add a warning message for invalid destinations -->
        <div v-if="!isValidDestination" class="invalid-destination-warning">
            Invalid destination. Orders cannot be placed.
        </div>

        <!-- Order Form -->
        <div class="order-form">
            <h3>Place Your Order</h3>
            <form @submit.prevent="placeOrder">
                <div class="form-group">
                    <label for="name-input">
                        Name
                    </label>
                    <input id="name-input" type="text" v-model="userName" placeholder="Enter your name" />
                </div>
            </form>

            <!-- Menu Items below the order status -->
            <div class="menu-container">
                <div class="menu">
                    <MenuItem v-for="(item, index) in menuItems" :key="index" :item="item" @order="placeOrder" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MenuItem from './MenuItem.vue';
import CustomerOrderStatus from './CustomerOrderStatus.vue';
import { v4 as uuidv4 } from 'uuid';
import { io } from 'socket.io-client';
import { ORDER_STATUS } from '../../constants/order-statuses';

export default {
    name: 'CustomerView',
    components: {
        MenuItem,
        CustomerOrderStatus
    },
    props: {
        destination: {
            type: String,
            default: 'Default Destination'
        }
    },
    data() {
        return {
            menuItems: [
                { name: 'Water', image: require('@/assets/open_water.png') },
                { name: 'Green Apple', image: require('@/assets/apple.png') },
                { name: 'Sprite', image: require('@/assets/sprite.png') },
                { name: 'Coke', image: require('@/assets/coke.png') },
                { name: 'Diet Coke', image: require('@/assets/diet_coke.png') }
            ],
            // menuItems: [
            //     { name: 'Water', image: require('@/assets/water.png') },
            //     { name: 'Sprite', image: require('@/assets/sprite.png') },
            //     { name: 'Coke', image: require('@/assets/coke.png') },
            //     { name: 'Coke Zero', image: require('@/assets/coke_zero.png') },
            //     { name: 'Blue Moon', image: require('@/assets/blue_moon.png') },
            //     { name: 'Stella Artois', image: require('@/assets/stella.png') },
            //     { name: 'Guinness', image: require('@/assets/guinness.png') },
            // ],
            orders: [],
            userId: '',
            userName: '',
            deliveryDestination: '',
            socket: null,
            isValidDestination: false,
            validDestinations: [],
            isCurrentOrdersScrollable: false,
            isPastOrdersScrollable: false,
            currentOrdersScrollOpacity: 1,
            pastOrdersScrollOpacity: 1,
            alertMessage: '',
            isErrorAlert: false,
            alertTimeout: null,
            isAcceptingOrders: true,
        }
    },
    created() {
        this.getUserId();
        const queryDestination = this.$route.query.d;
        this.deliveryDestination = queryDestination || 'Unknown Destination';
        this.fetchValidDestinations().then(() => {
            this.validateDestination();
        });

        this.fetchInitialData();
        this.fetchSettings();
    },
    mounted() {
        this.socket = io();
        this.setupSocketListeners();
        this.checkScrollable();
        window.addEventListener('resize', this.checkScrollable);
    },
    updated() {
        this.$nextTick(() => {
            this.checkScrollable();
        });
    },
    beforeUnmount() {
        if (this.socket) {
            this.socket.disconnect();
        }
        window.removeEventListener('resize', this.checkScrollable);
    },
    computed: {
        currentOrders() {
            return this.orders
                .filter(order => ![ORDER_STATUS.COMPLETED, ORDER_STATUS.CANCELLED].includes(order.status))
                .sort((a, b) => new Date(a.time) - new Date(b.time));
        },
        pastOrders() {
            return this.orders
                .filter(order => [ORDER_STATUS.COMPLETED, ORDER_STATUS.CANCELLED].includes(order.status))
                .sort((a, b) => new Date(b.time) - new Date(a.time));
        }
    },
    methods: {
        async fetchInitialData() {
            try {
                await this.fetchUserOrders();
            } catch (error) {
                console.error('Error fetching initial data:', error);
            }
        },

        async fetchUserOrders() {
            try {
                const response = await fetch('/api/orders');
                if (!response.ok) {
                    throw new Error('Failed to fetch user orders');
                }
                const allOrders = await response.json();
                this.orders = this.filterUserOrders(allOrders).map(order => ({
                    ...order,
                    progress: order.progress || 0
                }));
            } catch (error) {
                console.error('Error fetching user orders:', error);
                this.orders = [];
            }
        },

        filterUserOrders(orders) {
            if (Array.isArray(orders)) {
                return orders.filter(order => order.userId === this.userId);
            } else if (typeof orders === 'object') {
                return Object.values(orders)
                    .flat()
                    .filter(order => order.userId === this.userId);
            } else {
                console.error('Unexpected orders structure:', orders);
                return [];
            }
        },

        setupSocketListeners() {
            this.socket.on('orderUpdated', (data) => {
                const { order, newStatus } = data;
                // console.log('orderUpdated', order, newStatus);

                if (order.userId === this.userId) {
                    const index = this.orders.findIndex(o => o.orderId === order.orderId);
                    if (index !== -1) {
                        this.orders[index] = {
                            ...this.orders[index],
                            ...order,
                            status: newStatus,
                            progress: order.progress !== undefined ? order.progress : this.orders[index].progress || 0
                        };
                    } else {
                        // Add new order
                        this.orders.push({
                            ...order,
                            status: newStatus,
                            progress: order.progress || 0
                        });
                    }

                    // Sort orders after update
                    this.sortOrders();
                }
            });

            this.socket.on('settingsUpdated', (data) => {
                console.log('Received settingsUpdated event:', data);
                this.isAcceptingOrders = data.isAcceptingOrders;
            });
        },

        sortOrders() {
            // Sort current orders by placedAt (oldest first)
            this.orders.sort((a, b) => {
                if (a.status === ORDER_STATUS.COMPLETED && b.status !== ORDER_STATUS.COMPLETED) return 1;
                if (b.status === ORDER_STATUS.COMPLETED && a.status !== ORDER_STATUS.COMPLETED) return -1;
                return new Date(a.placedAt) - new Date(b.placedAt);
            });
        },

        getUserId() {
            const storedUserId = localStorage.getItem('userId');
            const storedTimestamp = localStorage.getItem('userIdTimestamp');
            const currentTime = new Date().getTime();

            if (storedUserId && storedTimestamp && (currentTime - parseInt(storedTimestamp) < 2 * 60 * 60 * 1000)) {
                this.userId = storedUserId;
            } else {
                this.userId = uuidv4();
                localStorage.setItem('userId', this.userId);
                localStorage.setItem('userIdTimestamp', currentTime.toString());
            }
        },

        async fetchValidDestinations() {
            try {
                const response = await fetch('/api/destinations/order/destinations');
                if (!response.ok) {
                    throw new Error('Failed to fetch valid destinations');
                }
                this.validDestinations = await response.json();
            } catch (error) {
                console.error('Error fetching valid destinations:', error);
            }
        },

        validateDestination() {
            this.isValidDestination = this.validDestinations.includes(this.deliveryDestination);
        },

        async placeOrder(item) {
            if (!this.isValidDestination) {
                this.showAlert('Invalid destination. Orders cannot be placed.', true);
                return;
            }

            if (!this.isAcceptingOrders) {
                this.showAlert('Sorry, Globar is not accepting orders at the moment. Please try again later.', true);
                return;
            }

            try {
                const response = await fetch('/api/orders', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        userId: this.userId,
                        userName: this.userName || 'Anonymous',
                        orderItems: [item.name],
                        destination: this.deliveryDestination,
                    })
                });

                const data = await response.json();
                if (data.canPlaceOrder) {
                    // this.showAlert('Order placed successfully!');
                } else {
                    this.showAlert(data.message, true);
                }
            } catch (error) {
                console.error('Error placing order:', error);
                this.showAlert('Failed to place order. Please try again.', true);
            }
        },

        async cancelOrder(orderId) {
            // console.log('Cancelling order:', orderId);
            try {
                const response = await fetch(`/api/orders/${orderId}`, {
                    method: 'DELETE',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to cancel order');
                }

                // this.showAlert('Order cancellation request sent successfully');
            } catch (error) {
                console.error('Error cancelling order:', error);
                this.showAlert('Failed to cancel order. Please try again.', true);
            }
        },

        checkScrollable() {
            if (this.$refs.currentOrdersContainer) {
                this.isCurrentOrdersScrollable = this.$refs.currentOrdersContainer.scrollHeight > this.$refs.currentOrdersContainer.clientHeight;
            }
            if (this.$refs.pastOrdersContainer) {
                this.isPastOrdersScrollable = this.$refs.pastOrdersContainer.scrollHeight > this.$refs.pastOrdersContainer.clientHeight;
            }
        },
        handleScroll(type) {
            const container = this.$refs[type === 'current' ? 'currentOrdersContainer' : 'pastOrdersContainer'];
            const scrollPercentage = container.scrollTop / (container.scrollHeight - container.clientHeight);
            const opacity = Math.max(0, Math.min(1, 1 - scrollPercentage * 2));
            
            if (type === 'current') {
                this.currentOrdersScrollOpacity = opacity;
            } else {
                this.pastOrdersScrollOpacity = opacity;
            }
        },
        showAlert(message, isError = false) {
            this.alertMessage = message;
            this.isErrorAlert = isError;
            
            // Clear any existing timeout
            if (this.alertTimeout) {
                clearTimeout(this.alertTimeout);
            }
            
            // Set a new timeout to dismiss the alert after 5 seconds
            this.alertTimeout = setTimeout(() => {
                this.dismissAlert();
            }, 5000);
        },
        
        dismissAlert() {
            this.alertMessage = '';
            this.isErrorAlert = false;
            if (this.alertTimeout) {
                clearTimeout(this.alertTimeout);
            }
        },

        async fetchSettings() {
            try {
                const response = await fetch('/api/settings');
                const data = await response.json();
                if (response.ok && data.success) {
                    this.isAcceptingOrders = data.isAcceptingOrders;
                } else {
                    console.error('Failed to fetch settings:', data.message);
                }
            } catch (error) {
                console.error('Error fetching settings:', error);
            }
        },
    }
}
</script>

<style scoped>
.customer-view {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    align-items: center; /* Center content horizontally */
    max-width: 400px; /* Match the max-width of the order form */
    margin: 0 auto; /* Center the entire view */
}

.order-section {
    width: 100%;
    max-width: 400px; /* Match the max-width of the order form */
}

.scrollable-container-wrapper {
    position: relative;
    width: 100%;
    max-width: 400px; /* Match the max-width of the order form */
}

.scrollable-container {
    max-height: 305px;
    overflow-y: auto;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 10px;
    background-color: #ffffff;
    width: 100%;
    box-sizing: border-box; /* Include padding in the width calculation */
}

.scrollable-container::-webkit-scrollbar {
    width: 8px;
}

.scrollable-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
}

.scrollable-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.scroll-indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20px;
    background: linear-gradient(to top, rgba(249, 249, 249, 1), rgba(249, 249, 249, 0));
    pointer-events: none;
    transition: opacity 0.2s ease;
}

.order-status-container {
    width: 100%;
}

.empty-order-message {
    text-align: center;
    color: #666;
    padding: 20px;
    font-style: italic;
}

.order-form {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}

.form-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.form-group label {
    flex: 1;
    text-align: left;
}

.form-group input {
    flex: 2;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    text-align: right;
}

.order-status-container {
    width: 100%;
}

.empty-order-status {
    width: 100%;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    margin-bottom: 20px;
}

.menu-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 400px; /* Match the max-width of the order form */
    box-sizing: border-box;
}

.menu {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    width: 100%;
}

.invalid-destination-warning {
    background-color: #ffcccc;
    color: #cc0000;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    text-align: center;
}

.past-orders-container {
    width: 100%;
    margin-top: 20px;
}

.alert {
    position: fixed;
    top: 20px; /* Moved higher for better visibility */
    left: 50%;
    transform: translateX(-50%);
    padding: 12px 20px;
    border-radius: 8px; /* Increased for a softer look */
    background-color: #4CAF50;
    color: white;
    z-index: 1000;
    cursor: pointer;
    max-width: 90%; /* Adjusted for better responsiveness */
    width: 400px; /* Set a fixed width */
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Added subtle shadow */
    font-size: 16px; /* Ensure readable font size */
    line-height: 1.4; /* Improve readability */
    transition: opacity 0.3s ease; /* Smooth transition for dismissal */
}

.alert-error {
    background-color: #f44336;
}

.alert-warning {
    background-color: #ff9800;
    color: white;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 4px;
}

/* New styles for different alert types */
.alert-success {
    background-color: #4CAF50;
}

.alert-info {
    background-color: #2196F3;
}

/* Add an icon to the alert */
.alert::before {
    content: '!';
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);
    margin-right: 10px;
}

/* Responsive design for smaller screens */
@media (max-width: 600px) {
    .alert {
        width: 90%;
        font-size: 14px;
        padding: 10px 15px;
    }
}

.warning-banner {
    background-color: #ffab40; /* More orange color */
    color: #333333; /* Dark gray text for better readability */
    border: 1px solid #ff9100; /* Darker border */
    border-radius: 4px;
    padding: 12px 20px;
    margin-bottom: -5px; /* Reduced from 15px */
    text-align: center;
    font-weight: bold;
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}
</style>