<template>
    <div class="spark-container">
      <div v-if="!isValidDestination" class="invalid-destination-warning">
        Invalid destination. Cannot call Spark to this location.
      </div>
      <div class="call-spark-container">
        <button class="call-spark-button" @click="callSpark" :disabled="isLoading || !isValidDestination">
          {{ isLoading ? 'Calling Spark...' : 'Come here Spark' }}
        </button>
      </div>
      <div v-if="!actionsDashboardEnabled" class="embed-container">
        <div class="embed-container-frame">
            <iframe
              src="https://embeds.inorbit.ai/embeds/rnLasGAxn5CP7bj32/LiAsP7f84NdIRxb_YQL-?ctx=(robot:(robotId:884102897))&appKey=u5qV7fbmDtRnNPgt"        
              width="100%" 
              height="100%" 
              style="border:none;" 
              scrolling="no"
            />
        </div>
      </div>
      <div v-if="actionsDashboardEnabled" class="embed-container">
        <div class="embed-container-frame">
          <iframe
            src="https://embeds.inorbit.ai/embeds/rnLasGAxn5CP7bj32/iQXOd1bVz6vj3VNPLVsm?ctx=(robot:(robotId:884102897))&appKey=u5qV7fbmDtRnNPgt"        
            width="100%" 
            height="100%" 
            style="border:none;" 
            scrolling="no"
          />
        </div>
      </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { io } from 'socket.io-client';

function generateUserId(){
  return Math.random().toString(36).substring(2, 15);
}

export default {
  name: "SparkControl",
  props: {
    destination: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      isLocked: ref(localStorage.getItem('isSparkLocked') === 'true'),
      actionsDashboardEnabled: ref(localStorage.getItem('actionsDashboardEnabled') === 'true'),
      userId: ref(localStorage.getItem('userId') || generateUserId()),
      socket: null,  // Store the socket connection
      isValidDestination: false,
      validDestinations: [],
    };
  },
  methods: {
    async fetchValidDestinations() {
      try {
        const response = await fetch('/api/destinations/spark/destinations');
        if (!response.ok) {
          throw new Error('Failed to fetch valid destinations');
        }
        this.validDestinations = await response.json();
      } catch (error) {
        console.error('Error fetching valid destinations:', error);
      }
    },

    validateDestination() {
      this.isValidDestination = this.validDestinations.includes(this.destination);
    },

    /**
     * Calls Spark to come to the specified destination.
     * The function first checks if Spark is locked by another user by calling
     * the /api/spark/call endpoint. If Spark is locked, the function throws an
     * error. Otherwise, the function sends a request to the /api/spark/call
     * endpoint with the destination as the request body. If the request is
     * successful, the function does nothing. If the request fails, the function
     * throws an error.
     * The function also displays an alert to the user if Spark is locked or if
     * the request fails.
     * @param {string} destination - The location name to call Spark to.
     * @throws {Error} - If Spark is locked or if the request fails.
     */
    async callSpark() {
      if (!this.isValidDestination) {
        alert('Invalid destination. Cannot call Spark to this location.');
        return;
      }

      this.isLoading = true;
      try {
        const response = await fetch('/api/spark/call', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            userId: localStorage.getItem("userId"),
            destination: this.destination,
          })
        });
        const data = await response.json();
        if (!data.success) {
          throw new Error(data.message);
        }
      } catch (error) {
        // TODO(MarianoCereda): Handle this 'alerts' differently.
        console.error('Error calling Spark:', error);
        if (error.message.includes("Spark is locked")) {
          alert('Spark is locked by another user. Please try again later.');
        } else if (error.message.includes("Spark is busy")) {
          alert('Spark is busy on another mission. Please try again later.');
        } else if (error.message.includes("Spark is offline")) {
          alert('Spark is offline. Please try again later.');
        } else if (error.message.includes("Spark is not ready for navigation")) {
          alert('Spark is not ready for navigation. Please try again later.');
        } else {
          alert('Failed to call Spark. Please try again.');
        }
      } finally {
        this.isLoading = false;
        console.log(`The user Id: ${localStorage.getItem("userId")}`);
      }
    },
    
    /**
     * Enable the actions dashboard. Local storage is updated accordingly.
     */
    enableActionsDashboard() {
      localStorage.setItem("actionsDashboardEnabled", "true");
      this.actionsDashboardEnabled = true;
    },
    
    /**
     * Disable the actions dashboard. Local storage is updated accordingly.
     */
    disableActionsDashboard() {
      localStorage.setItem("actionsDashboardEnabled", "false");
      this.actionsDashboardEnabled = false;
    },

    /**
     * Update the component's state and local storage when the robot is locked/unlocked.
     * @param {boolean} isLocked - True if the robot is locked, false otherwise.
     */
    setRobotLocked(isLocked) {
      this.isLocked = isLocked;
      localStorage.setItem('isSparkLocked', isLocked ? 'true' : 'false');
    },
    
    /**
     * Disable the actions dashboard when the robot is unlocked.
     * @param {object} data - The event data with the robotId property.
     */
    handleRobotUnlocked(data) {
      console.log(`Robot was unlocked ${data.robotId}. Disabling dashboard.`);
      this.setRobotLocked(false);
      const currentDashoardStatus = localStorage.getItem("actionsDashboardEnabled");
      if (currentDashoardStatus === "true") {
        this.disableActionsDashboard();
      }
    },
    
    /**
     * Called when a mission is completed. Enables the actions dashboard.
     * @param {Object} data - The mission completed event data.
     * @param {string} data.robotId - The id of the robot that completed the mission.
     */
    handleSayHiCompleted(data) {
      console.log(`Robot ${data} was unlocked. Enabling dashboard.`);
      this.setRobotLocked(true);
      this.enableActionsDashboard();
    },

    handleSayHiNotCompleted(data) {
      console.log(`Robot ${data} didn't complete the mission.`);
      alert('Spark failed to execute the mission. Please, try again later.');
      this.setRobotLocked(false);
      this.disableActionsDashboard();
    },

    handleFinished(data) {
      console.log(`Robot ${data.robtoId} finished`);
      this.setRobotLocked(false);
      this.disableActionsDashboard();
    }
  },
  /**
   * Lifecycle hook that is called when the component is mounted.
   * Connects to the Socket.IO backend and listens for the
   * "robotUnlocked" and "missionCompleted" events. When the events
   * are received, the corresponding methods are called.
   */
  mounted() {
    this.fetchValidDestinations().then(() => {
      this.validateDestination();
    });

    const backendUrl = process.env.VUE_APP_BACKEND_URL || 'http://localhost:8082';
    console.log(`Backend URL: ${backendUrl}`);
    this.socket = io(backendUrl);  // Connect to backend Socket.IO
    this.socket.on('robotUnlocked', this.handleRobotUnlocked);
    this.socket.on('sayHiCompleted', this.handleSayHiCompleted);
    this.socket.on('sayHiNotCompleted', this.handleSayHiNotCompleted);
    this.socket.on('finished', this.handleFinished);
  },
  /**
   * Called when the component is about to be unmounted.
   * Disconnects from the Socket.IO backend.
   */
  beforeUnmount() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
};
</script>

<style scoped>
.spark-container {
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #f5f5f5;
}

.call-spark-container {
  text-align: center;
  margin: 20px 0 30px 0;
  padding: 10px;
}

.call-spark-button {
  padding: 15px 30px;
  font-size: 20px;
  background-color: #C0D639;
  color: black;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 250px;
  font-weight: 600;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.call-spark-button:hover:not(:disabled) {
  background-color: #a8bc33;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.call-spark-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.embed-container {
  width: 100%;
  height: calc(100vh - 180px); /* Increased height */
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  align-content: center;
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  align-content: center;
}

/* Tablet styles */
@media (max-width: 1024px) {
  .call-spark-button {
    padding: 12px 24px;
    font-size: 18px;
    min-width: 220px;
  }

  .embed-container {
    height: calc(100vh - 160px);
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .spark-container {
    padding: 10px;
  }

  .call-spark-button {
    padding: 12px 24px;
    font-size: 18px;
    min-width: 200px;
  }

  .embed-container {
    height: calc(100vh - 140px);
  }
}

.invalid-destination-warning {
  background-color: #ffcccc;
  color: #cc0000;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}
</style> 